<template>
	<FailedJobsList />
</template>

<script>
import FailedJobsList from "../components/FailedJobsList.vue";
import { defineComponent, onMounted } from "vue";
import { setPageHeader } from "../../../core/helpers/toolbar";
export default defineComponent({
	components: {
		FailedJobsList,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.FAILED_JOBS",
				actionButton: null,
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.JOBS_MONITOR", link: "#" },
					{ name: "message.JOBS_MONITOR", link: "/jobs-monitor" },
					{ name: "message.FAILED_JOBS" },
				],
			});
		});
	},
});
</script>
