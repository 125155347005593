
import { defineComponent, ref, onMounted, watchEffect } from "vue";
import { Apollo, Notify } from "@/core/services";
// import Search from "@/components/search/Search.vue";
import { useI18n } from "vue-i18n";
import Table from "../../../components/Table/Table.vue";
import { Modal } from "bootstrap";
import InnerLoader from "../../../components/InnerLoader.vue";
import { GET_FAILED_JOBS, SEARCH_FAILED_JOBS } from "@/modules/failed-jobs/graphql/Queries";
import { DELETE_FAILED_JOB, RETRY_FAILED_JOB } from "@/modules/failed-jobs/graphql/Mutations";
import { dropdown_handler } from "../../../core/helpers/dropdownHandler";
import { useStore } from "vuex";

export default defineComponent({
	name: "subscription list",
	components: {
		// Search,
		Table,
		InnerLoader,
	},
	setup() {
		const i18n = useI18n();
		const store = useStore();
		const loader = ref(false);
		const loading = ref(false);
		const failed_jobs = ref({}) as Record<any, any>;
		const modal: any = ref();
		const failed_scenarios = ref("");
		const popup_title = ref("");
		const systemLocale = ref();
		const activeIndex = ref(-1);

		const columns = ref([
			{
				label: "message.ID",
				key: "id",
			},
			{
				label: "message.CONNECTION",
				key: "connection",
			},
			{
				label: "message.QUEUE",
				key: "queue",
			},
			// {
			// 	label: "message.PAYLOAD",
			// 	key: "payload",
			// 	textAlignment: "center",
			// },
			// {
			// 	label: "message.EXCEPTION",
			// 	key: "exception",
			// 	textAlignment: "center",
			// },
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);
		const filterObject = ref([
			{
				label: "message.QUEUE",
				value: "queue",
			},
		]);
		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const searchHandler = () => {
			const searchData = store.getters.getSearchData;
			loader.value = true;
			Apollo.watchQuery({
				query: SEARCH_FAILED_JOBS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				variables: {
					filter: searchData.data.select,
					search_key: searchData.data.input,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				loader.value = false;
				failed_jobs.value = JSON.parse(data.search_failed_jobs);
			});
		};

		const get_failed_jobs = () => {
			loader.value = true;
			const sub = Apollo.watchQuery({
				query: GET_FAILED_JOBS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					Notify.error(i18n.t("message.SOMETHING_WENT_WRONG"));
				}
				loader.value = false;
				failed_jobs.value = JSON.parse(data.failed_jobs);
			});
		};

		const getScenario = (data, title) => {
			failed_scenarios.value = data;
			popup_title.value = title;
			modal.value = new Modal(document.getElementById("pop_up_modal"));
			modal.value.show();
		};

		const retryJob = (uuid) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_HIT_JOB_AGAIN")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.YES"),
					cancelButtonText: i18n.t("message.NO"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					Apollo.mutate({
						mutation: RETRY_FAILED_JOB,
						variables: { uuid: uuid },
						update: (store, { data: { retry_job } }) => {
							const readParse = store.readQuery({
								query: GET_FAILED_JOBS,
							}) as Record<any, any>;
							const readData = JSON.parse(readParse.failed_jobs);
							const data = readData.filter((failed_job: any) => failed_job.uuid !== retry_job);
							store.writeQuery({
								query: GET_FAILED_JOBS,
								data: {
									failed_jobs: [JSON.stringify(data)],
								},
							});
							loader.value = false;
							Notify.success(i18n.t("message.JOB_IS_HIT_SUCCESSFULLY"));
						},
					}).catch(() => {
						loader.value = false;
					});
				});
		};

		const deleteJob = (uuid) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.YES"),
					cancelButtonText: i18n.t("message.NO"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					Apollo.mutate({
						mutation: DELETE_FAILED_JOB,
						variables: { uuid: uuid },
						update: (store, { data: { delete_job } }) => {
							const readParse = store.readQuery({
								query: GET_FAILED_JOBS,
							}) as Record<any, any>;
							const readData = JSON.parse(readParse.failed_jobs);
							const data = readData.filter((failed_job: any) => failed_job.uuid !== delete_job);
							store.writeQuery({
								query: GET_FAILED_JOBS,
								data: {
									failed_jobs: [JSON.stringify(data)],
								},
							});
							loader.value = false;
							Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
						},
					}).catch(() => {
						loader.value = false;
					});
				});
		};

		onMounted(() => {
			get_failed_jobs();
			document.addEventListener("click", handleClickOutside);
		});

		return {
			loader,
			filterObject,
			failed_jobs,
			columns,
			failed_scenarios,
			popup_title,
			loading,
			get_failed_jobs,
			searchHandler,
			deleteJob,
			retryJob,
			getScenario,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
